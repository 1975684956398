

 import { Box,Text,useColorMode,Button, VStack } from '@chakra-ui/react'
//  import PropTypes from 'prop-types'
import React, { useState } from 'react'
 import Header from './header'
 import MyContext from '../context/MyContext'
 import { useMetaData } from '../hooks/use-meta-data'
import { useLocalStorage } from '../hooks/use-localstorage'
//import { Helmet } from 'react-helmet'

import {Link} from 'gatsby'
 
 const Layout = ({ children }) => {
  const [engl,setEng] = useState(false)
  const data = useMetaData()
  const colorMode=useColorMode()
  const [name,setName] = useLocalStorage('name',"")
 


 const Meldung=()=>{
  
  return(
    <VStack maxWidth="500">
    <Box my="6">
      
      <Text p="0" fontSize="md">
        Beachten Sie unsere Datenschutzbestimmungen<br/>
        Sind Sie mit unserer Datenverwaltung einverstanden?
        
      </Text>
       
      <Box> 
            <Button my="2" onClick={()=>{
                setName("yes-einverstanden")
              }}>Ja
            </Button>{" "}
            <Button my="2" onClick={()=>{
                setName("nein-einverstanden")
              }}>Nein 
            </Button>
            </Box>
      <Link to="/datenschutz"> &rarr; Unsere Datenschutzbestimmungen lesen</Link>
    </Box>
    </VStack>
  )
}
  
   return (
    <>
   
 
    <MyContext.Provider value={{engl,setEng,name,setName}}>
  
    <Box  as="div"
         mx= "auto"
         maxWidth="1000px"
         height="100%"
         py="3"
         px={["3","4","4"]}
         
         position="relative"
        >
      {
      name===""  && 
      <Box bg="teal.400" py="10" 
      position='absolute' zIndex="20" 
      width={["90vw","50vw",'40vw']} maxWidth="1300" 
      height="300" top="0" left="0" right="0">
        
      <Meldung />
     
        
       </Box>     
   
   
   }
   
      <Header siteTitle={data.site.siteMetadata.title || `Ausstellungsparcours`} />  
       
         <Box   mt={["30px","50px","60px","70px"]}   as="main">{children}</Box>
        
        <Box as="footer" bg={colorMode.colorMode==="dark"?"gray.800":"whiteAlpha.600"} 
        rounded="2"  marginTop="2rem" fontSize="md">
        <Text fontSize="xs" textAlign="center" color={colorMode.colorMode==="light"?"gray.800":'white'}  >
           © {new Date().getFullYear()} 
           {` `} 
         K.O.M </Text>
         </Box>
       </Box>
      
       </MyContext.Provider>
       </>
   )}
//  Layout.propTypes = {
//    children: PropTypes.node.isRequired,
//  }
 
 export default Layout
module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jüdischer Parcours","short_name":"Parcours","description":"Jüdischer Parcours 2022. Eine Ausstellung während des Theaterfestivals ","lang":"de","display":"standalone","icon":"src/images/icon.png","start_url":"/","background_color":"#663399","theme_color":"#fff","localize":[{"start_url":"/","lang":"de","name":"Jüdischer Parcours 2022 in Bacharach und Oberwesel","short_name":"Parcours","description":"Jüdischer Parcours 2022. Eine Ausstellung"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3431a33d7b3d93f200cdc3cef81f30b6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import { graphql, useStaticQuery } from "gatsby"

export const useMetaData = () =>{
  const data = useStaticQuery(graphql`
  query {
    site {
         siteMetadata {
              author
              description
              siteUrl
              title
              keywords
            }
          }
    }
      

   `)
   return data
}


import { ChakraProvider,extendTheme} from '@chakra-ui/react'
import SimpleReactLightbox from 'simple-react-lightbox'
import React from 'react'
import "@fontsource/roboto";

import Layout from './components/layout'

const Heading = {

  baseStyle:{
    fontWeight: 300,
    py:0,
    fontFamily:"Roboto"
     },
  sizes: {
    sm: {
      fontSize: 'sm',
      py:"2",
      
    },
    md: {
      fontSize: 'md',
      py:"3",
     
    },
    xl:{
      fontSize:'xl',
      my:0,
      py:0
    },

    '2xl':{
      fontSize:'2xl',
      my:0,
      py:0
      
    }
  },
 variants:{ 
  MeH: {
    lineHeights:1.3,
    fontWeight:400,
    fontSize:['xl','2xl'],
    px:0,
    py:4
    },
    utitel: {
      fontWeight:500,
      fontSize:'md',
      fontFamily:'Roboto, sans-serif',
      pt:6,
      pb:2,
      '_hover':{
        color:'teal'
      },
      cursor:"pointer",
     
    },
    Titel: {
      fontFamily:"Roboto",
      lineHeights:1.3,
      fontWeight:500,
      letterSpacing:".04rem",
      fontSize:['2xl','3xl','4xl'],
      px:0,
      py:4
    
   },
   btitel: {
    fontFamily:"Roboto",
    lineHeights:1.3,
    fontWeight:500,
    letterSpacing:".04rem",
    fontSize:['2xl','3xl','3xl'],
    px:0,
    py:4
   },
   starttitel: {
    
    lineHeights:1.3,
    fontWeight:500,
    letterSpacing:".04rem",
    fontSize:['lg','xl','xl'],
    fontFamily:"Roboto",
    
    py:4,
    textAlign:"left",
    display:'block'
  
 },
   defaultProps: {
     size:'2xl',
   }
  }
}

const Text = {

  baseStyle:{
    fontWeight: 400,
    
    fontFamily:"Roboto"
     },
    //  sizes: {
    //   sm: {
    //     fontSize: 'md',
    //     py:"2",
        
    //   },
    //   md: {
    //     fontSize: 'md',
    //     py:"3",
       
    //   },
    //   xl:{
    //     fontSize:'lg',
    //     my:0,
    //     py:0
    //   },
  
    //   '2xl':{
    //     fontSize:'2xl',
    //     my:0,
    //     py:0
        
    //   }
    // }, 
 variants:{ 
   solid:{
     fontFamily:"Roboto", 
     fontWeight:400,
     py:3,
     fontSize:["sm","md","md","md"],
    
     
   },
   start:{
    fontFamily:"Roboto", 
    fontWeight:400,
    pb:3,
    px:3,
    lineHeight: [1.3,1.6,1.5],
    
    
    fontSize:['md',"md"]
   
  },
  startlinks:{
    py:3,pl:0 ,
    fontSize:"sm",
    fontWeight:600,
   
    _hover:{
      color:'teal.500'
    }
   
  },
   links:{
     py:3,
     fontSize:["xl","xl","xl"],
     color: 'blue.500',
     _hover:{
       color:'teal.500'
     }
   },
  MeA: {
    lineHeights:1.3,
   
    fontSize:['lg','xl'],
    px:0,
    mb:0,
   },
   VeA: {
    lineHeights:1.1,
    fontSize:['md','lg','xl'],
    px:0,
    py:0,
    mb:0,
    fontWeight: 300,
   },
   Bild: {
     fontSize: 'xs',
     fontWeight: 'normal',
     pb:5,
     pt:1
   },
   SBild: {
    fontSize: 'xs',
    fontWeight: 'normal',
   
    pb:2,
    pt:1,
    textAlign:'left'
  },
   defaultProps: {
     size:'sm',
     
   }
  }
}
export const theme = extendTheme({
config: {
  initalColorMode:'light'
},
components: {
 Text,
 Heading

 },
colors: {
  brand: {
    100: '#E0AAFF',
    200: '#C77DFF',
    300: '#9D4EDD',
    400: '#7B2CBF',
    500: '#5A189A',
    600: '#3C096C',
    700: '#240046',
    800: '#10002B',
    900: '#070014',
  },
},
fontSizes: {
  xs: '12px',
  sm: '14px',
  md: '16px',
  lg: '18px',
  xl: '20px',
  '2xl': '24px',
  '3xl': '28px',
  '4xl': '36px',
  '5xl': '48px',
  '6xl': '64px',
},
fontWeights: {
  normal: 400,
  medium: 500,
  bold: 700,
},
lineHeights: {
  normal: 'normal',
  none: '1',
  shorter: '1.25',
  short: '1.375',
  base: '1.5',
  tall: '1.625',
  taller: '2',
},
letterSpacings: {
  tighter: '-0.05em',
  tight: '-0.025em',
  normal: '0',
  wide: '0.025em',
  wider: '0.05em',
  widest: '0.1em',
},
breakpoints: ['20em','30em', '48em', '62em', '80em'],

})


export const wrapPageElement = ({ element }) => {
   
  return (

    <ChakraProvider theme={theme} resetCSS>
       
      <Layout><SimpleReactLightbox>{element}</SimpleReactLightbox> </Layout>
     
     
    </ChakraProvider>
  )
}
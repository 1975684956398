import { Box, Flex,Text, Badge,useDisclosure,
    DrawerBody,DrawerHeader,DrawerOverlay,DrawerCloseButton,DrawerContent,Drawer,List,ListItem,VisuallyHidden, Divider
    } from '@chakra-ui/react'
    
    import {Link} from 'gatsby'
    import PropTypes from 'prop-types'
    import React from 'react'
    import { FaAlignJustify } from 'react-icons/fa'
    import ThemeToggle from './theme-toggle'
    import { useColorMode } from '@chakra-ui/react'
     
  
import { StaticImage } from 'gatsby-plugin-image'

    
    function PlacementExample() {
     //const cm=useColorMode()
      const { isOpen, onOpen, onClose } = useDisclosure()
    
      const btnRef = React.useRef()
      return (
       <>
       
        <Box   mx="0">
          
         <Badge pt={["0","0","5"]} ml="3" bg="whiteAlpha.200"  fontSize="2xl"  onClick={onOpen}>
          <FaAlignJustify bg="white"  fontSize="24px"/>
          </Badge> 
          <Drawer 
          mt="8"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        colorScheme ="whiteAlpha"
        >
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
              <DrawerHeader><Link to="/pdf/verluste.pdf">    <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="xl"  fontWeight="300">&rarr; Programmheft</Text></Link></DrawerHeader>
              <DrawerBody opacity="1" >
             
                <List  height="100vh" cursor="pointer" onClick={onClose}  fontSize="2xl" >
                  
               
             <ListItem pb="8">
                    <Link 
                     to="/termine">
                        <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="xl"  fontWeight="300">&rarr; Termine</Text>
                     </Link>
                </ListItem> 
               <ListItem fontSize={["md","md"]} py="2">
                    <Link to="/beitrag-1">
                        <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="xl" fontWeight="400">1.
                       Jüdisches Leben im Hunsrück </Text>
                    </Link>
                </ListItem> 
              <ListItem py="2">
                    <Link to="/beitrag-2">
                        <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="xl" fontWeight="400">2.
                       Das Schicksal der Bacharacher und Oberweseler Juden </Text>
                    </Link>
                </ListItem>
                <ListItem py="2">
                    <Link  to="/beitrag-3">
                        <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="xl" fontWeight="400">3.
                        
                          Virtuelle Rekonstruktion der Synagogen von Simmern und Hottenbach </Text>
                    </Link>
                </ListItem>
                <ListItem py="2">
                    <Link  to="/beitrag-4">
                       <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="xl" fontWeight="400">4.
                      Der Wernerkult </Text>
                    </Link>
                </ListItem>
              <ListItem py="2">
                    <Link  to="/beitrag-5">
                       <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="xl" fontWeight="400">5.
                      Menschen, Stimmen, Erinnerungen</Text>
                    </Link>
                </ListItem>
                
                <ListItem py="2">
                    <Link to="/beitrag-6">
                       <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="xl" fontWeight="400">6. 
                      
                       Gemälde von Ferdinand Friess</Text>
                    </Link>
                </ListItem>
                <ListItem py="2">
                    <Link  to="/beitrag-7">
                       <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="xl" fontWeight="400">7. 
                      
                       Bruchstücke des Roten Fensters</Text>
                    </Link>
                </ListItem>
                <ListItem py="2">
                    <Link  to="/beitrag-8">
                       <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="xl" fontWeight="400">8.
                      
                      Schabbes</Text>
                    </Link>
                </ListItem>

                <ListItem py="2">
                    <Link to="/stolpersteine">
                        <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="xl" fontWeight="400">Stolpersteine</Text>
                    </Link>
                </ListItem>

                <ListItem py="2">
                    <Link  to="/gedenkliste">
                         <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}}  letterSpacing=".09rem" fontSize="xl"fontWeight="400">Gedenkliste</Text>
                    </Link>
                </ListItem>
                <ListItem py="2">
                    <Link  to="/verbote">
                        <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="xl"fontWeight="400">Antisemitische Gesetze</Text>
                    </Link>
                </ListItem>
            
                <ListItem py="2">
                    <Link  to="/beteiligte">
                       <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="xl" fontWeight="400">Beteiligte</Text>
                     </Link>
                </ListItem> 
                <Divider />
                <ListItem pt="10" pb="2" >
                    <Link to="/impressum">
                       <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="lg" fontWeight="400">Impressum</Text>
                     </Link>
                </ListItem>
                <ListItem py="2">
                    <Link  to="/kontakt">
                       <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="lg" fontWeight="400">Kontakt</Text>
                     </Link>
                </ListItem>
                <ListItem py="2">
                    <Link  to="/datenschutz">
                       <Text lineHeight="1.3rem"  _hover={{color:'blue.400',fontWeight:300}} letterSpacing=".09rem" fontSize="lg" fontWeight="400">Datenschutz</Text>
                     </Link>
                </ListItem>
             </List>
            
               
              </DrawerBody>
            </DrawerContent>
          </Drawer>
          </Box>
          </>
      )
    }
    
    const Header = ({ siteTitle }) => {
     const cm=useColorMode()
    
      return(
        
     
            <Box   width={["88%","90%"]}  px="0"  maxHeight="200" >
             <VisuallyHidden>{siteTitle}</VisuallyHidden>
              <Flex mb="0"  maxWidth={1100}   flexDirection={["row","row"]} alignItems="flex-end" justifyContent="flex-end">
             
              <Box mb={["5","5","0"]}>
                <ThemeToggle />
              </Box>
             
              <Box position="relative"  mb="14" pr="0" mt="1"   style={{cursor:'pointer'}} >
                 <Box position="absolute" zIndex="0" top="1" >
      
                <PlacementExample/>
           
                </Box>
                </Box>
              
              </Flex>
              <Box>
         
             <Flex   flexDirection={["column","column","row"]}>
               
             
             <Box  mt="-16"  pt="0" width={["84%","90%"]}>
             { cm.colorMode==="light"?
                    <Link to="/">
                    <StaticImage src="../images/logos/verluste_header.jpg" alt="logo"/>
                    </Link>
                    :
                   
                    <Link to="/">
                  <StaticImage src="../images/logos/verluste_header_neg.png" alt="logo"/>
                    </Link>
                  }
                  </Box>
             </Flex>
             
                
            
               </Box>
            </Box>
        
        
    
    
      )
    }
    
    Header.propTypes = {
      siteTitle: PropTypes.string,
    }
    
    Header.defaultProps = {
      siteTitle: ``,
    }
    
    export default Header